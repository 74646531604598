
//custom components
import SideBar from '@/components/SideBar.vue'

//init vue component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      sideBarOpen: false,
      scroll: false,
      menuOptions: [{
        text: 'Inicio',
        link: '/#inicio'
      },
      {
        text: 'Próximos Estrenos',
        link: '/#proximos-estrenos'
      },
      {
        text: 'Nuestras Películas',
        link: '/#nuestras-peliculas'
      },
      {
        text: 'Contacto',
        link: '/#contacto'
      }]
    }
  },
  components:{
    SideBar
  },
  mounted() {
    //change navbar color flag
    window.addEventListener('scroll', () => {
      this.onScroll()
    })
  },
  methods: {
    onScroll() {
      this.scroll = (window.pageYOffset > 0) ? true : false
    },
    toggleSideBar() {
      this.sideBarOpen = !this.sideBarOpen
    }
  }
});
