
// import Swiper JS
import Swiper, { Navigation } from 'swiper'

// import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

//axios
import axios from 'axios';

//custom components
import MovieItem from '@/components/MovieItem.vue'

//init vue component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HomeView',
    data() {
        return {
            config: [] as any[any],
            movies: [] as any[any],
            showComingSoonSwiper: false,
            showOurFilmsSwiper: false
        }
    },
    components: {
        MovieItem
    },
    async mounted() {
        //get config
        const getConfig = await axios.get('https://admin.terrorificofilms.com.ar/api/items/config/1?fields=*,pelicula_destacada.*,pelicula_destacada.poster.data,pelicula_destacada.banner.data,pelicula_destacada.logo.data,proximos_estrenos.*,proximos_estrenos.*,proximos_estrenos.peliculas_id.*,proximos_estrenos.peliculas_id.poster.*,proximos_estrenos.peliculas_id.banner.*')
        this.config = getConfig.data.data

        //get movies
        const getMovies = await axios.get('https://admin.terrorificofilms.com.ar/api/items/peliculas?fields=*,poster.data.*.*&sort=-fecha_de_estreno,titulo_local')
        this.movies = getMovies.data.data

        //Coming Soon Swiper
        const comingSoonSwiper = new Swiper('.comingSoonSwiper', {
            slidesPerView: 1.2,
            spaceBetween: 8,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
            modules: [Navigation],
            breakpoints: {
                768: {
                    slidesPerView: 2.1,
                    spaceBetween: 16,
                    slidesOffsetBefore: 32,
                    slidesOffsetAfter: 32,
                }
            },
            navigation: {
                nextEl: '.coming-soon-next',
                prevEl: '.coming-soon-prev',
            },
            init: this.showComingSoonSwiper = true
            
        })

        //Our Films Swiper
        const ourFilmsSwiper = new Swiper('.ourFilmsSwiper', {
            slidesPerView: 2.4,
            spaceBetween: 8,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
            modules: [Navigation],
            breakpoints: {
                768: {
                    slidesPerView: 4.2,
                    spaceBetween: 16,
                    slidesOffsetBefore: 32,
                    slidesOffsetAfter: 32,
                }
            },
            navigation: {
                nextEl: '.our-films-next',
                prevEl: '.our-films-prev',
            },
            init: this.showOurFilmsSwiper = true
        })
    },
    methods:{
        getFormatedDate: function(date:any) {
            date = new Date(date + 'T12:00:00.000Z')
            date = new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires' }).format(date)
            return date
        }
    }
});
