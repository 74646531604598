import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e4bc12f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "movieItem" }
const _hoisted_2 = {
  key: 1,
  class: "poster bg-stone-600 rounded-md animate-pulse"
}
const _hoisted_3 = {
  key: 2,
  class: "text-white truncate font-semibold text-sm mt-2 uppercase lg:text-lg"
}
const _hoisted_4 = {
  key: 3,
  class: "h-4 w-full bg-stone-600 rounded-md mt-3 animate-pulse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.poster)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "poster relative bg-stone-600 rounded-md",
          style: _normalizeStyle($props.poster ? { backgroundImage: 'url(' + $props.poster + ')' } : null)
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    ($props.title && $props.showTitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.title), 1))
      : ($props.showTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4))
        : _createCommentVNode("", true)
  ]))
}